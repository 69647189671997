<template>
  <div class="clinical-assessment h-100">
      <div class="container h-100 py-5">
      <h3 class="text-capitalize pt-2 pt-md-5" data-aos="fade-down-right" data-aos-easing="ease-in-out" data-aos-duration="400">{{this.clinical_assessment.Label}}</h3>
      <div class="row row-wrapper d-flex align-items-center justify-content-center">
        <div class="row py-5">
          <div class="col-md-12" data-aos="fade-right"  data-aos-easing="ease-in-out" data-aos-duration="600">
            <div class="card shadow rounded mb-5">
              <div class="card-body pt-md-5 px-md-5">
                <ul class=" questions">
                  <li v-for="question in clinical_assessment.questions" :key="question.id" class="mb-md-5 mb-2">
                    <h5>{{question.question}}</h5>
                    <ul class="answer mt-md-3 mt-2">
                        <li v-for="answer in question.answers" :key="answer.id" class="d-flex answer-li">
                            <div :class=" answer.is_correct == 1 &&  rationalAppearance ? 'form-check success' : 'form-check'">
                                <input class="form-check-input cursor-pointer" :disabled="rationalAppearance" type="checkbox" :value="answer" v-model="selected" :name="'flexRadioDefault'+answer.id" :id="'flexRadioDefault1'+answer.id">
                                <p class="form-check-label text-muted" for="flexRadioDefault1">
                                    {{answer.value}}
                                </p>
                            </div>

                            <!-- <div class="alert alert-success mt-4 correct-answer" role="alert" v-if="rationalAppearance && answer.is_correct == 1">
                              <strong>Correct answer is : </strong>{{answer.value}}
                            </div> -->
                        </li>

                        <div class="alert alert-warning mt-4" role="alert" v-if="rationalAppearance">
                          <strong>Rational: </strong>{{question.rational}}
                        </div>

                        <hr class="my-4">
                    </ul>
                  </li>
                </ul>
                <div class="alert alert-danger d-flex justify-content-between" role="alert" v-if="success == false">
                  <span class="mt-1">
                    You failed!
                  </span>
                  <button class="btn btn-primary" @click="tryAgain()" >Try Again</button>
                </div>
                <div class="alert alert-success d-flex justify-content-between" role="alert" v-if="success == true">
                  <span class="mt-1">
                    You passed the exam, <strong>Click next to continue</strong>
                  </span>
                </div>
              </div>
              <div class="card-footer py-2 text-right">
                <button class="btn btn-primary btn-rounded submit" @click="submit()" :disabled="rationalAppearance">submit</button>
              </div>
            </div>
            
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel"></h5>
            </div>
            <div class="modal-body text-center" id="modal_body">
              please answer all questions
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
    name: 'Clinical Assessment',
    data(){
        return{
            clinical_assessment:[],
            selected: [],
            final: [],
            rationalAppearance: false,
            success: null,
            counter: 0,
            correctAnswersCount: 0,
        }
    },
    methods:{
        getClinicalAssessment(){
            this.clinical_assessment = this.$store.getters['clinicalAssessment']
        },

        submit(){
          // reset the array
          this.final = [];

          // prepare the final array to send it to the backend
          for (let index = 0; index < this.selected.length; index++) {
             this.final.push({ 'answer_id' : this.selected[index].id , 'question_id' : this.selected[index].question_id, 'is_correct' : this.selected[index].is_correct});
          };
        
          var a1 = [];
          var a2 = [];
          var result = [];
          this.final.forEach(element => {
            a1.push(element.question_id)
          });
          this.clinical_assessment.questions.forEach(element => {
            a2.push(element.id)
          });

          result = a2.filter(x => !a1.includes(x))

          // show "fill all questions" message because all questions must be answered
          if(result.length > 0){
            $('#staticBackdrop').modal('show')
          }

          else{

          // increment counter value if there any wrong answer [values returned from back end (1 is correct , 0 is incorrect)] 
            this.final.forEach((value, index) => {
              if(value.is_correct == '0'){
                ++this.counter
              }
            })

          // to count the correct answers in all question that returned from the backend 
          this.clinical_assessment.questions.forEach(element => {
            element.answers.forEach(el => {
                if(el.is_correct == '1'){
                  ++this.correctAnswersCount
                }
              });
          });
          
          // set success status depend on counters values
          if(this.counter == 0 && this.final.length == this.correctAnswersCount){
            this.success = true
          }
          else{
            this.success = false
          }

          //send the final answers and success status to the backend 
            if(this.success == true) {
              this.$store.dispatch( "clinicalAssessment", { 'status': 'Success' , 'answers': this.final} ).then(_=>{
                this.$store.dispatch('nextNavigationStatus', true)
              })
            }
            else {
              this.$store.dispatch( "clinicalAssessment", { 'status': 'Failed' , 'answers': this.final} )
            }

            this.rationalAppearance = true

          }
        },

        tryAgain(){
          this.$store.dispatch('prevNavigationStatus', true)
          this.$store.dispatch('nextNavigationStatus', true)
          // this.$store.state.currentState = 0
          this.$store.state.step = 11
          this.$store.state.stateStep = 0
          this.$store.dispatch( 'trialTime')
          this.$router.push({ name: `step11`, query: {'exp': this.$store.state.case_key} })
        }
    },
    mounted() {
        this.getClinicalAssessment()
        this.$store.dispatch('setCurrentState')
        this.$store.dispatch('prevNavigationStatus', false)
        this.$store.dispatch('nextNavigationStatus', false)
        this.$store.dispatch( 'setInstructions', this.$store.getters['clinicalAssessment'].instructions )
        this.$store.dispatch( 'setStartTime')
    },
    beforeRouteLeave (to, from, next) {
      this.$store.dispatch( 'setEndTime' , this.$options.name)
      next()
    }
}
</script>

<style scoped>
.answer{
    list-style: none;
}
.text-right{
  text-align: right;
}
.questions{
  font-family: Arial, Helvetica, sans-serif;
}
.questions h5{
  font-weight: unset !important;
  /* font-size: unset !important; */
  color: #000;
}

.questions p{
  font-weight: unset !important;
  font-size: unset !important;
}

hr{
  margin-left: -50px;
  background: rgba(0, 0, 0, 0.253);
  border: 0;
  height: 2px;
}

.success{
  /* color : #99e0a9; */
  background-color: #99e0a9;
}
.success > input {
  margin-top: 10px ;
  margin-bottom: 10px ;
}

.success > p {
  margin-top: 6px;
}

.submit{
  background-color: #48D8D8;
  font-size: large;
  border-color: #48D8D8;
  border-bottom: 2px solid #002148;
  border-radius: 13px;
  padding: 5px 30px;
}

.answer-li{
  display: flex !important; 
  flex-direction: column !important;
}

.answer-li > .form-check { order: 1 !important; }
.answer-li > .correct-answer { order: 2 !important; }

@media (max-width: 768px) {
  .submit{
    background-color: #48D8D8;
    font-size: 0.5rem;
    border-color: #48D8D8;
    border-bottom: 2px solid #002148;
    border-radius: 5px;
    padding: 5px 15px;
  }
}
</style>